import { Injectable } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import {Md5} from 'ts-md5/dist/md5';
import {ApiUrlConstant} from '../../apiUrl.constant'; 
@Injectable()
export class LoginService {
  userIPAddress: any;
  customerData: any;
  role_type: string;
  player_data: any;
  userTermCondition: any;
  headers=new Headers(); 
  forgot_password: any;
  httpOptions:any;
  organization_data: any;
  role_id: string;
  specificRole_access_control_details: any;
  organization_details_data: any;
  constructor(private http_client:HttpClient) {
      
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        })
    }; 
  }

  checkCredentials(email:string, login_flag:string)
  { 
    let params = new HttpParams();
    login_flag == 'e'?params =params.append('email_address', email):params =params.append('mobile_number', email);    
    params =params.append('login_flag', login_flag);   
    const local_authetication_url = AppComponent.API_URL + ApiUrlConstant.AuthenticateUser;
    return this.http_client.post(local_authetication_url,params,this.httpOptions)
      .pipe(
          map((data:any) =>{
              // if(data.length!=0){         
                return data;
              // }
              // else{return '';}
          }),
        ) 
  }

  checkCredentialsV2(email:string, password:string, loginflag:string)
  {    
    let params = new HttpParams();
    if (loginflag == 'e') 
    {
      params =params.append('email_address', email);          
    }
    else if (loginflag == 'm') 
    {
      params =params.append('mobile_number', email);       
    }
    const md5 = new Md5();    
    let md5_password:any;
    md5_password=md5.appendStr(password).end();
    params =params.append('password', md5_password.toString());  
    params =params.append('login_flag', loginflag); 
  
    const local_authetication_url = AppComponent.API_URL + ApiUrlConstant.AuthenticateUser;
    return this.http_client.post(local_authetication_url, params,this.httpOptions)
      .pipe(
            map((data:any) =>{
                       
              return data;
              
            }),
        )
  }
  checkCredentialsV3(email:string, password:string, loginflag:string,otp:string) 
  {    
    let params = new HttpParams();
    if (loginflag == 'e') 
    {
      params =params.append('email_address', email);          
    }
    else if (loginflag == 'm') 
    {
      params =params.append('mobile_number', email);             
    }
    if(otp=='OTP'){
      params =params.append('otp', password);     
    }else{
      const md5 = new Md5();
      let md5_password=md5.appendStr(password).end();
      params =params.append('password', md5_password.toString());  
    }     
    params =params.append('login_flag', loginflag); 
    params =params.append('device_os', 'web');
    params =params.append('device_id', '' + localStorage.getItem("device_id")); 
    params =params.append('session_id', '' + localStorage.getItem("session_id"));
    params =params.append('device_details', '' + this.detectBrowserName() + '|' + this.detectBrowserVersion());
    
  
    const local_authetication_url = AppComponent.API_URL + ApiUrlConstant.UserAuthenticate;
    return this.http_client.post(local_authetication_url, params)
      .pipe(
            map((data:any) =>{     
              return data;               
            }),
        )
  }
  /*Method for checking the role of a user */
  checkAuthorization(customer_id:string)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),     
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
    const  params = new  HttpParams().set('customer_id', customer_id);
                                      // .set('mobile_app_id', '000');

    
    this.role_type = "";
    localStorage.setItem('customer_id', '' + customer_id);      
    
    const local_authorization_url = AppComponent.API_URL + ApiUrlConstant.Authorization;
    return this.http_client.post(local_authorization_url, params,this.httpOptions)

    .pipe(map((data:any) =>{
      
      if(data['status']==200){
        this.customerData =data['data'];
        localStorage.setItem('UserRole', JSON.stringify(this.customerData));                
        for (var i = 0; i < this.customerData['user_role'].length; i++) {          
          if (this.customerData['user_role'][i].role_type == "H") 
          {
            this.role_type = "H";
            break;
          }
          else if (this.customerData['user_role'][i].role_type == "A") 
          {
            this.role_type = "A";
            break;
          }
          else 
          {
            continue;
          }
        }    
        //alert(this.role_type)   
        if (this.role_type == "H") 
        {
          //localStorage.setItem('Flag', '' + this.role_type);
          return true;
        }

        else if (this.role_type == "A") 
        {
          //localStorage.setItem('Flag', '' + this.role_type);
          return true;
        }
        else
        {
          return false;
        }
      }
        
      })
    );     
  }

  /*Method for retrieving the IP address of a user */  
  getUserTermCondition()
  { 
    const  params = new  HttpParams().set('type', 'terms').set('status', 'active').set('locale', 'IN');
    const local_url=AppComponent.API_URL+ApiUrlConstant.GetTermsOfUse;    
    return this.http_client.post(local_url,params,{headers: this.httpOptions})
     .pipe(
            map((data:any) =>{
                this.userTermCondition =data['data'];
                if(this.userTermCondition.length!=0)
                {         
                  return this.userTermCondition;
                }
                else
                  {
                    return '';
                  }
            }),
        )       
  }
  getOrganizationList(CustomerId:string)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
    const  params = new  HttpParams().set('customer_id', CustomerId);    
   
    const local_authetication_url=AppComponent.API_URL+ApiUrlConstant.GetOrganizationsList;
    return this.http_client.post(local_authetication_url,params,this.httpOptions)

    .pipe(
          map((data:any) =>{
            this.organization_data =data['data']['organization_list'];
            if(this.organization_data.length!=0)
            {         
              return data['data'];
            }
            else
              {
                return '';
              }
          }),
        )
  }
  getChildOrganizations(login_customer_id:string,academy_parent_id:string)
  {    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
    const  params = new  HttpParams().set('login_user_id', login_customer_id)
                                    .set('academy_parent_id', academy_parent_id);    
    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetChildOrganizations;  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
             return data
            }),
        )
  }

  refreshTokenApi(){
    
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': '' + localStorage.getItem("auth_key"),
      // 'device_id': '' + localStorage.getItem("device_id"),
      // 'session_id': '' + localStorage.getItem("session_id"),
      'Authorization':''+ localStorage.getItem("access_token")
      })
    };

    const  params = new  HttpParams().set('refresh_token', localStorage.getItem("refresh_token")!)
                                     .set('customer_id', localStorage.getItem("customer_id")!)
                                     .set('organization_id', sessionStorage.getItem("OrganizationID")!)
                                     .set('role_type', localStorage.getItem("Flag")!);
    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetAccessToken;  
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
    map((data:any) =>{               
      return data;     
    }));
  }


  getCountriesCode(){  
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };     
  const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetCountries;  
  return this.http_client.post(local_playerlist_url, '',this.httpOptions)  
    .pipe(map((data:any) =>{                  
      if(data['countries_list'])
      {   
            
        return data;
      }
      else
        {
          return '';
        }
    }),
  )
}

GetStates(country_id:string)
{
  this.httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': '' + localStorage.getItem("auth_key"),
      // 'device_id': '' + localStorage.getItem("device_id"),
      // 'session_id': '' + localStorage.getItem("session_id"),
      'Authorization':''+ localStorage.getItem("access_token")
      })
  };
  let params = new HttpParams();    
  params =params.append('country_id', country_id); 
  const local_getState_url = AppComponent.API_URL + ApiUrlConstant.GetStates;
  return this.http_client.post(local_getState_url,params,this.httpOptions)
  .pipe(
          map((data:any) =>{
              if(data['states_list'].length!=0)
              {         
                return data['states_list'];
              }
              else
                {
                  return '';
                }
          }),
      )
}
GetCities(country_id:string,state_id:string)
{
  this.httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': '' + localStorage.getItem("auth_key"),
      // 'device_id': '' + localStorage.getItem("device_id"),
      // 'session_id': '' + localStorage.getItem("session_id"),
      'Authorization':''+ localStorage.getItem("access_token")
      })
  };
  let params = new HttpParams();    
  params =params.append('country_id', country_id); 
  params =params.append('state_id', state_id); 
  const local_getCities_url = AppComponent.API_URL + ApiUrlConstant.GetCities;
  return this.http_client.post(local_getCities_url,params,this.httpOptions)
  .pipe(
          map((data:any) =>{
              if(data['cities_list'].length!=0)
              {         
                return data['cities_list'];
              }
              else
                {
                  return '';
                }
          }),
      )
}

addOrganization(form:any,customer_id:string,academy_parent_id:string)
{
  this.httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',     
      // 'auth_key': '' + localStorage.getItem("auth_key"),
      // 'device_id': '' + localStorage.getItem("device_id"),
      // 'session_id': '' + localStorage.getItem("session_id"),
      'Authorization':''+ localStorage.getItem("access_token")
      })
  };
  let params = new HttpParams(); 
  params =params.append('academy_parent_id', academy_parent_id);      
  params =params.append('organization_online_payment_flag', form.organization_online_payment_flag); 
  var moment_date=form.organization_establish_date['_d'];
  var est_date=moment_date.getDate()+'/'+(moment_date.getMonth()+1)+'/'+moment_date.getFullYear();
  params =params.append('organization_establish_date', est_date); 
  params =params.append('organization_name', form.organization_name); 
  params =params.append('organization_email', form.organization_email); 
  params =params.append('cheque_tracking_flag', form.cheque_tracking_flag); 
  params =params.append('zipcode', form.zipcode); 
  params =params.append('organization_kit_provided_flag', form.organization_kit_provided_flag); 
  params =params.append('country_code', form.country_code); 
  params =params.append('organization_contact_number', form.organization_contact_number); 
  params =params.append('organization_biometric_taken_flag', form.organization_biometric_taken_flag); 
  params =params.append('capture_parents_info_flag', form.capture_parents_info_flag); 
  params =params.append('address_line1', form.address_line1); 
  params =params.append('address_line2', form.address_line2==null?'':form.address_line2); 
  params =params.append('organization_app_access', form.organization_app_access); 
  params =params.append('state_id', form.state_id); 
  params =params.append('customer_id', customer_id); 

  params =params.append('LTAD_flag', form.LTAD_flag); 
  params =params.append('country_id', form.country_id); 
  params =params.append('sport_type',form.sport_type); 
  params =params.append('city_id', form.city_id);

  params =params.append('organization_facebook_link',form.organization_facebook_link==null?'':form.organization_facebook_link); 
  params =params.append('organization_website_link', form.organization_website_link==null?'':form.organization_website_link); 

  

  const local_getState_url = AppComponent.API_URL + ApiUrlConstant.AddOrganization;
  return this.http_client.post(local_getState_url,params,this.httpOptions)
  .pipe(
          map((data:any) =>{
            
                      
                return data;
             
          }),
      )
  }

  getSportType()
  {      
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetSportType;
    return this.http_client.post(state_list_url,[],this.httpOptions)
    .pipe(
          map((data:any) =>{
            if(data['sport_type_details'].length!=0)
            {         
              return data['sport_type_details'];
            }
            else
              {
                return '';
              }
          }),
        )
  }

    
  getAccessControlDetailsByRoleID(role_id)  
  {

    const params = new HttpParams().set('role_id',role_id);     
    const local_getAccessControlDetailsByRoleID_url=AppComponent.API_URL+ApiUrlConstant.GetRoleEntitlementDetails;
    return this.http_client.post(local_getAccessControlDetailsByRoleID_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.specificRole_access_control_details =data['role_entitlement_details']['access_control_details'];
            if(this.specificRole_access_control_details.length!=0)
            {         
              return this.specificRole_access_control_details;
            }
            else
              {
                return '';
              }
          }),
        )
  } 

  getOrganizationDetails(OrganizationID,customer_id)
  {  
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
      };

      let params=new HttpParams();
      params =params.append('organization_id', OrganizationID); 
      params =params.append('customer_id', customer_id);  
   
      const local_authetication_url=AppComponent.API_URL+'organization/GetOrganizationDetails';
      return this.http_client.post(local_authetication_url,params,this.httpOptions)
      .pipe(
            map((data:any) =>{
              this.organization_details_data =data['data']['academy_details'];
              if(this.organization_details_data.length!=0)
              {         
                return this.organization_details_data;
              }
              else
                {
                  return '';
                }
            }),
          )
  }
  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   
  detectBrowserVersion(){
      var userAgent = navigator.userAgent, tem, 
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }
      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }

}