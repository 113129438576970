import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../../../login_services/login.service';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {TranslateService} from '@ngx-translate/core';
import { CommonApiService } from '../../../../common-api/common-api.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class OrganizationSelectorComponent implements OnInit {
  organization_list:any;
  organization_list1:any=[];
  public organization_selector_form: FormGroup;
  organization_id:string;
  id: string;
  private sub: any;
  complete_organization_list:any=[]; 
  login_customer_id: any;
  child_academies: any=[];
  child_flag: boolean=false;
  parent_academy: any;
  dataSource = new MatTableDataSource(this.child_academies);
  NoRecord_flag: boolean=false;
  parent_academy_id: any;
  parent_academy_primary_flag: boolean=false;

  org_list_dataSource: any=new MatTableDataSource(this.organization_list1);
  mahd_flag: boolean;
  sportz_village_flag: boolean;
  yalla_goal_flag: boolean;
  sports_pad_flag: boolean;
  @ViewChild('parentLandingSelector') parentLandingSelector: TemplateRef<any>;
  @ViewChild('addAcademy') addAcademy: TemplateRef<any>;
  add_academy_form:FormGroup;

  countries_code_list: any=[];
  countries_list_dataSource = new MatTableDataSource(this.countries_code_list);
  countries_list: any;
  country_id: any;
  state_id: any;
  city_id: any;
  getSates_data: any;
  getCities_data: any;
  countries_code_list_temp: any;
  form_invalid_flag: boolean;
  allow_admin_to_add_child_academy: boolean=false;
  mobile_regex: RegExp;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  actionButtonLabel: string = 'Close';
  action: boolean = true;
  success_msg: string;

  today:Date=new Date();
  sport_type_list: any;
  parentChildAcademySameFlag: boolean=false;
  organization_app_access_field:boolean=true;
  showDeleteRequestLink: boolean ;
  role_id: any;
  roles_entitlements: any;
  role_type: any;
  role_administration_flags: any;
  allow_owner_to_add_child_academy: any;
  is_Admin: any;
  organization_details: any;
  role_name: any;
  login_as_flag: boolean;
  roles_array: any;
  selected_org_id: any;
  constructor(private router: Router,private route:ActivatedRoute,private fb: FormBuilder,
              private loginService:LoginService,public dialog: MatDialog,public snackBar: MatSnackBar,
              private translate:TranslateService,private commonApiService:CommonApiService) 
  {    
    
    // this.commonApiService.localeEvent.subscribe(locale=>this.translate.use(locale));    

    this.mahd_flag = sessionStorage.getItem('mahd_flag')=='Y'?true:false;
    this.sportz_village_flag = sessionStorage.getItem('sportz_village_flag')=='Y'?true:false;   
    this.yalla_goal_flag = sessionStorage.getItem('yalla_goal_flag')=='Y'?true:false;
    this.sports_pad_flag = sessionStorage.getItem('sports_pad_flag')=='Y'?true:false;
    this.organization_list=JSON.parse(localStorage.getItem('organization_list'));
    this.login_customer_id =localStorage.getItem('customer_id');
    this.organization_list.forEach(element => {
      var role_id=0;
  
      element['role_types'].forEach(role => {
        if(role=="A" || role=="H")
        {
          role_id=1;
          if(role=="A"){
            this.showDeleteRequestLink = true;
          }
        }
     
      });
      if(role_id==1){
        this.complete_organization_list.push(element);
      }
    });
    const browserLang = sessionStorage.getItem("locale")!=null?sessionStorage.getItem("locale"):'en';
    translate.use(browserLang.match(/en|hi/) ? browserLang : 'en');
    this.allow_owner_to_add_child_academy=localStorage.getItem('add_child_academy_by_owner');
    this.is_Admin=localStorage.getItem('is_Admin');
   
    
  }
   ngOnInit() 
   {  
      this.sub = this.route.params.subscribe(params => {
      this.id = params['organization_id'];      
    });
    this.academySwitch();
    this.organization_selector_form = this.fb.group ( {
      Organization_list: [null , Validators.compose ( [ Validators.required] )],
      role_list: [null , Validators.compose ( [ Validators.required] )]
    } );


    this.add_academy_form = this.fb.group({
      organization_name: [null, Validators.required],
      organization_app_access: ['G', Validators.required],
      organization_establish_date: [null, Validators.required],
      organization_email: [null , Validators.compose ( [ Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      sport_type:[null, Validators.required],
      zipcode: [null, Validators.required],
      address_line1: [null, Validators.required],
      address_line2: new FormControl,
      country_id: [null, Validators.required],
      state_id: [null, Validators.required],
      city_id: [null, Validators.required],
      country_code: [null, Validators.required],
      organization_contact_number: [null, Validators.required],
      cheque_tracking_flag: ['N', Validators.required],
      organization_kit_provided_flag: ['N', Validators.required],
      organization_biometric_taken_flag: ['N', Validators.required],
      capture_parents_info_flag: ['N', Validators.required],
      LTAD_flag: ['N', Validators.required],
      organization_facebook_link: new FormControl,
      organization_website_link: new FormControl,
      organization_online_payment_flag: ['N', Validators.required], 
    });
  }
  academySwitch()
  {
    if(this.id!=undefined)
    {
       this.selectorganization(this.id,'','Different');     
    }
    else if(this.login_customer_id=='9063'){
      this.child_flag=true;
      this.selectorganization('2393','','Different'); 
      localStorage.setItem('Organization_list_1',JSON.stringify(this.organization_list1));
    }
    else
    {              
      this.organization_list1 = Array.from(new Set(this.complete_organization_list));    
      localStorage.setItem('Organization_list_1',JSON.stringify(this.organization_list1));
      this.org_list_dataSource.data=this.organization_list1;
    }
  }
   selectorganization(org_data,from,parentChildAcademySameFlag)
   {          
     if(from=='form')
     {
      this.organization_id=org_data['Organization_list'];
     }
     else{
      this.organization_id=org_data; 
     }
     this.dialog.closeAll();
     this.parentChildAcademySameFlag=parentChildAcademySameFlag=='Equal'?true:false;
    this.getChildOrg();
    
   } 
   getChildOrg(){
    
    this.loginService.getChildOrganizations(this.login_customer_id,this.organization_id).subscribe(
      response => {
        
        if (response['status']==200) {  
          if(response['data']['organization_details']!=false){
            
            if(response['data']['organization_details']['child_academies'].length!=0)
            {  
              if(!this.parentChildAcademySameFlag){
                this.child_flag=true;
                this.allow_admin_to_add_child_academy=response['data']['organization_details']['allow_admin_to_add_child_academy']=='Y'?true:false;
                this.child_academies=response['data']['organization_details']['child_academies'];
                this.dataSource.data = this.child_academies;
                this.parent_academy=response['data']['organization_details'];
                this.parent_academy_id=response['data']['organization_details']['academy_parent_id']; 
              }else{
                this.child_academies=[];
                this.child_flag=false;            
                this.login_academy(this.organization_id); 
              }
                                
              
            }
            else{
              this.child_academies=[];
              this.child_flag=false;            
              this.login_academy(this.organization_id);                    
            }
          }
          else{
            // this.child_academies=[];
            // this.child_flag=false;            
            this.login_academy(this.organization_id);                    
          }
        
        }
        else{
          this.child_academies=[];
            this.child_flag=false;
            this.login_academy(this.organization_id);  

        }
      }); 
   }

  login_academy(academy_id)
  {  
    
    
    if(this.parent_academy_id==academy_id && this.parent_academy_primary_flag)
    {
      sessionStorage.setItem('parent_academy_flag','Y');
      localStorage.setItem('childAcademy',null);
    }
    else{
      sessionStorage.setItem('parent_academy_flag','N');  
    }
   
    var org_data=[];   
    this.complete_organization_list.forEach(element => {
      if(element.organization_id==academy_id)
      {
        org_data.push(element);
      }
    });

    if(org_data.length!=0){
      var admin=0;
      var headoffice=0;
      var admin_role=0;
      var content_creator=0;
      
      org_data[0]['role_types'].forEach(element => {
        
        if(element=='H')
        {
          headoffice=1;
        }
        if(element=='A')
        {
          admin=1;
        }
      });
      org_data[0]['role_names'].forEach(element => {
        
        if(element=='Admin')
        {
          admin_role=1;
        }
        if(element=='Content Creator')
        {
          content_creator=1;
        }
      });
      if(admin==1)
      {
        localStorage.setItem('Flag', 'A');
      }
      if(headoffice==1 && admin==0)
      {
        localStorage.setItem('Flag', 'H');
        
      }    
      sessionStorage.setItem('OrganizationID',academy_id);
      if(admin_role==1 || headoffice==1) 
      {
        localStorage.setItem('Content_creator_flag', 'N');
        this.router.navigate(['dashboard'],{ queryParams: { page: 1 }  });
        this.refreshToken('1');       

      }
      if(content_creator==1 && admin_role==0)
      {
        localStorage.setItem('Content_creator_flag', 'Y');
        this.refreshToken('2');
       
      }
   
    }
    else{
      this.success_msg="Sorry, You can not access this academy.";
      this.snackBarDisplay();
    }
   
  
  }
  
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  back()
  {
    this.child_flag=false;
  }
  applyFilter(searchField)
  {
    const filterValue = searchField;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.child_academies=this.dataSource['filteredData'];
    if(this.dataSource['filteredData'].length==0)
     {
       this.NoRecord_flag=true;
     }
     else{
       this.NoRecord_flag=false;
     }
  }
 
  
  primaryOrg(academy_parent_id){    
    this.parent_academy_id=academy_parent_id;
    this.dialog.open(this.parentLandingSelector,
      {      
        width:'450px',
        disableClose:true,
      });  
  }
  parentAcademyLogin()
  {    
    this.parent_academy_primary_flag=true;
    this.login_academy(this.parent_academy_id);
  }
  parentAcademyAdminLogin()
  {
    this.parent_academy_primary_flag=false;
    this.login_academy(this.parent_academy_id);
  }

  refreshToken(e){
    this.loginService.refreshTokenApi().subscribe(
      response=>
      {     
        if(response['status']==200)
        {               
          localStorage.setItem('access_token', response['data']['access_token']);
          localStorage.setItem('refresh_token', response['data']['refresh_token']);
          if(e=='1'){
            this.router.navigate(['dashboard'],{ queryParams: { page: 1 }  });
          }
          if(e=='2'){
            this.router.navigate(['/coaching-drill']);
          }
        }              
        else
        {
              
        }
      });
  }

  filterAcademy(filterValue){
  
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); 
    this.org_list_dataSource.filter = filterValue;
    this.organization_list1=this.org_list_dataSource['filteredData'];
  } 
  codeSelection(){
    this.organization_list1=Array.from(new Set(this.complete_organization_list)); 
  }
  addAcademyDialog(){
    this.getCountriesCode();
    this.getSportsType();
    this.dialog.open(this.addAcademy,
      {      
        width:'650px',
        disableClose:true,
      });
    this.add_academy_form.get('organization_app_access').setValue('G');
  }


  getCountriesCode(){
    this.loginService.getCountriesCode().subscribe(
    response => {
      if (response != '') {  
             
        this.countries_code_list=response['countries_list'];   
        this.countries_code_list_temp=response['countries_list'];   
        this.countries_list = response['countries_list'];       
        this.countries_list_dataSource.data=this.countries_code_list;
      }
      else {
        this.countries_code_list=[];        
      }
    });
  }
  filterCountryCode(filterValue){
  
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); 
    this.countries_list_dataSource.filter = filterValue;
    this.countries_code_list=this.countries_list_dataSource['filteredData'];
  }
  
  
  selectedcountry(contry_id) {

    this.country_id = contry_id;
    this.loginService.GetStates(this.country_id).subscribe(
      response => {
        if (response != '') {
          this.getSates_data = response;

        }
        else {
          this.getSates_data = [];
        }
      });
  }
  selectedstate(state_id) {
    this.state_id = state_id;
    this.loginService.GetCities(this.country_id, this.state_id).subscribe(
      response => {
        if (response != '') {
          this.getCities_data = response;
        }
        else {
          this.getCities_data = [];
        }
      });
  }
  selectedcity(city) {
    this.city_id = city;
  }
  closeDialog(){
    this.add_academy_form.reset();
  }

  addChildAcademy(){    
    if(this.add_academy_form.valid){
      this.form_invalid_flag=false;
      
      this.loginService.addOrganization(this.add_academy_form.value,this.login_customer_id,this.organization_id).subscribe(
        response => {
          if (response['organization_details']['organization_id'] != '') {
            this.success_msg="Academy Added Successfully";
            this.snackBarDisplay();
            this.getChildOrg();
            this.getOrganizationList(response['organization_details']['organization_id']);
            this.dialog.closeAll();
          }
          else {
            this.dialog.closeAll();
            this.success_msg=response['organization_details']['message'];
            this.snackBarDisplay();
          }
        });
    }
    else{
      this.form_invalid_flag=true;
    }
  
  }

  countryCodeSelected(type) {
    var country_code = this.add_academy_form.get('organization_contact_number').value;
    if (type == 'a') {
      var country_code = this.add_academy_form.get('organization_contact_number').value;
      this.add_academy_form.get('organization_contact_number')!.setValidators([Validators.compose([Validators.required])]);
      this.add_academy_form.get('organization_contact_number')!.updateValueAndValidity();
    }
  
    this.countries_code_list.forEach(element => {
      if (element.country_code == country_code) {
        this.mobile_regex = new RegExp('^[0-9][0-9]{' + (element.contact_no_length - 1) + ',' + (element.max_contact_no_length - 1) + '}$');
        if (type == 'a') {
          this.add_academy_form.get('organization_contact_number')!.setValidators([Validators.compose([Validators.required, Validators.pattern(this.mobile_regex)])]);
          this.add_academy_form.get('organization_contact_number')!.updateValueAndValidity();
        }
      
      }
    });

  }
  getSportsType() {
    this.loginService.getSportType().subscribe(
      response => {
        if (response != '') {
          this.sport_type_list = response;
        }
        else {
          this.sport_type_list = [];
        }
      });
  }
  snackBarDisplay() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.success_msg, this.action ? this.actionButtonLabel : undefined, config);
  }

  noAdmin(){
    this.success_msg="Sorry, You can not access this academy.";
    this.snackBarDisplay();
  }

  getOrganizationList(org_id){
    this.loginService.getOrganizationList(this.login_customer_id).subscribe(
      response => {            
        
          if (response['organization_list'].length != 0) {

            if (response['is_admin']) {
              localStorage.setItem('is_Admin', '1');
            }
            else {
              localStorage.setItem('is_Admin', '0');
            }
            response['organization_list'].forEach(element => {
              var role_id=0;
              
              element['role_types'].forEach(role => {
                if(role=="A" || role=="H")
                {
                  role_id=1;
             
                }              
                
              });
              if(role_id==1){
                this.complete_organization_list.push(element);
              }
            });
            this.login_academy(org_id);
          
           
          }
      });
  }
  changeLocale(locale:string){
    this.commonApiService.changeLocale(locale);
  }
  getOrganizationDetail(org_id){
    this.loginService.getOrganizationDetails(org_id,this.login_customer_id).subscribe(
      response=>{
        this.organization_details=response['user_roles'];
         this.roles_array =  this.organization_details
        .filter(element => element.role_type === 'H' || element.role_type==='A')
        .map(element => ({
            role_id: element['role_id'],
            role_type: element['role_type'],
            role_name: element['role_name']
        }));
        if(this.roles_array.length>1){
          this.login_as_flag=true;
          this.organization_selector_form.get('role_list')!.setValidators([Validators.compose([Validators.required])]);
          this.organization_selector_form.get('role_list')!.updateValueAndValidity();
        }
        else{
          this.login_as_flag=false;
          this.organization_selector_form.get('role_list')!.clearValidators();
          this.organization_selector_form.get('role_list')!.updateValueAndValidity();
          this.organization_details.forEach(element => {
            if(element.role_type==='H' || element.role_type==='A'){
                this.role_id=element['role_id'];
              }
            });
          this.getRoles(this.role_id);
        }     
      }
    )
  }

  getRoles(roleId){
    this.loginService.getAccessControlDetailsByRoleID(roleId).subscribe(
      response=>{
        
         if(response!=''){
          this.roles_entitlements=response;
          this.roles_entitlements.forEach(element => {
            this.role_type=element['role_type'];
              this.role_administration_flags=element['code'];
              if(this.role_type=='H'){
              if(this.role_administration_flags=='add_child_academy_by_owner'){
                localStorage.setItem('add_child_academy_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
              if(this.role_administration_flags=='add_modify_centre_batch_by_owner'){
                localStorage.setItem('add_modify_centre_batch_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
              if(this.role_administration_flags=='add_modify_role_by_owner'){
                localStorage.setItem('add_modify_role_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
              if(this.role_administration_flags=='add_modify_staff_by_owner'){
                localStorage.setItem('add_modify_staff_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
              if(this.role_administration_flags=='add_modify_fee_plan_by_owner'){
                localStorage.setItem('add_modify_fee_plan_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
              if(this.role_administration_flags=='chnage_branding_preference_by_owner'){
                localStorage.setItem('chnage_branding_preference_by_owner', element['answer'] === 'yes' ? 'yes' : 'no');
              }
            }
          });
        }
        else{
          this.roles_entitlements=[];
          this.role_administration_flags= localStorage.setItem('add_child_academy_by_owner','yes');
          this.role_administration_flags= localStorage.setItem('add_modify_centre_batch_by_owner','yes');
          this.role_administration_flags= localStorage.setItem('add_modify_role_by_owner','yes');
          this.role_administration_flags= localStorage.setItem('add_modify_staff_by_owner','yes');
          this.role_administration_flags= localStorage.setItem('add_modify_fee_plan_by_owner','yes');
          this.role_administration_flags= localStorage.setItem('chnage_branding_preference_by_owner','yes');
        }
      }
    )
  }

  getOrgDetail(org_data,from,parentChildAcademySameFlag){
    this.selected_org_id=org_data;
    this.loginService.getOrganizationDetails(this.selected_org_id,this.login_customer_id).subscribe(
      response=>{
        this.organization_details=response['user_roles'];
         this.roles_array =  this.organization_details
        .filter(element => element.role_type === 'H' || element.role_type==='A') 
        .map(element => ({
            role_id: element['role_id'],
            role_type: element['role_type'],
            role_name: element['role_name']
        }));

        this.parentChildAcademySameFlag=parentChildAcademySameFlag=='Equal'?true:false;
          if(!this.parentChildAcademySameFlag){
            if(this.roles_array.length>1){
            this.dialog.open(this.loginAs,
              {
                //height:'500px',
                width:'350px',
                disableClose:true,
              })     
              this.organization_selector_form.get('role_list')!.setValidators([Validators.compose([Validators.required])]);
              this.organization_selector_form.get('role_list')!.updateValueAndValidity();
              this.organization_selector_form.get('Organization_list')!.clearValidators();
              this.organization_selector_form.get('Organization_list')!.updateValueAndValidity();
            }
            else{
              this.organization_details.forEach(element => {
                if(element.role_type==='H' || element.role_type==='A'){
                    this.role_id=element['role_id'];
                  }
                });
              this.getRoles(this.role_id);
              this.selectorganization(this.selected_org_id,'',parentChildAcademySameFlag);
            }     
        }
        else{
          this.organization_details.forEach(element => {
            if(element.role_type==='H' || element.role_type==='A'){
                this.role_id=element['role_id'];
              }
            });
          this.getRoles(this.role_id);
          this.selectorganization(this.selected_org_id,'',parentChildAcademySameFlag);
        }     
      }
    )
  }

  loginAsRole(){
    this.selectorganization(this.selected_org_id,'',this.parentChildAcademySameFlag);
  }
  @ViewChild('loginAs') loginAs: TemplateRef<any>;
}
