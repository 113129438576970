<div class="uk-grid">
  <mat-progress-bar mode="indeterminate" class="inner-loader" [style.display]="spinner_div"></mat-progress-bar>
  <div class="uk-width-medium-6-10"></div>
</div>
 
<div class="uk-grid student-dashboard" style="margin-top:0px;">
  <div class="uk-width-1-1">
    <ng-container *ngIf="publish_report_list=='[]'">
      <div class="md-card no-shadow">
        <div class="md-card-content"> No Data Available</div>
      </div>
    </ng-container>

    <ng-container>
      <div class="uk-grid">
        <div class="uk-width-medium-10-10"></div>
      </div>

      <div class="uk-grid" style="margin-top:0px; padding: 0px 9px;" *ngIf="organization_id!='1784' && organization_id!='1783'" [style.background]="report_card_color">
        <div class="uk-width-medium-1-1" *ngIf="specific_student_report_card_data">
          <table border="0" cellpadding="10" cellspacing="5" [style.background]="report_card_color" width="100%" class="flexibleContainer" *ngIf="report_type=='by_criteria'" >
            <tr *ngIf="specific_student_report_card_data[0]['add_cover_page_flag']==1">
              <td>
                <div > 
                  <img [src]="specific_student_report_card_data[0]['aws_cover_page_image']"
                      style="margin: 0px auto;width: 100%;"/> 
                  
                </div>
              </td>
            </tr>
          </table>          
          
          <table border="0" cellpadding="10" cellspacing="5" [style.background]="report_card_color" width="100%" class="flexibleContainer" >
              <tr>
                <td valign="top" width="100%" class="flexibleContainerCell">

                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" >
                    <tr>
                      <td style="width:70px;"><img [src]="academy_logo"  width="60" style="width:60px;" /></td>
                      <td>
                        <h3 style="margin:0px 0px;" [style.color]="text_color">{{organization_name}}</h3>
                        <h4 style="margin:0px 0px;" [style.color]="text_color">{{preference_center_name}} : {{academy_center_name}}</h4>
                      </td>
                    </tr>  
                    <tr>
                      <td colspan="2">
                        <h3 [style.color]="text_color" style="letter-spacing: 1px;  text-align:center; font-weight:bold; font-size:18px; margin:5px 0px; font-family:Helvetica,Arial,sans-serif; text-transform:uppercase;">{{reportname}}</h3>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
          </table>
    
          <table border="0" cellpadding="30" cellspacing="5" width="100%" bgcolor="#f7f7f7" style="  border-radius:8px;" [style.box-shadow]="box_shadow" [style.background]="card_color">
              <tr *ngFor="let data of specific_student_report_card_data;let i=index">
                <td align="left" valign="top" class="textContent" width="100" style="width:80px; padding:10px;">
                  <p style="text-align:center; margin: 7px 0px;"> 
                      <img [src]="data.player_image"  width="100%" style="border-radius: 6px; width:80px; margin: 0px auto;" onerror="this.src='assets/images/pi.png'" />  
                  </p> 
                </td>
                <td align="left" valign="top" class="textContent" style="padding:10px;">
                    <table width="100%">
                        <tr>
                          <td colspan="2">
                            <h3 [style.color]="report_header" style="line-height: 28px; letter-spacing: 1px;text-align:left; font-weight:bold; font-size:18px; margin:0px; font-family:Helvetica,Arial,sans-serif;">{{data.player_name}}</h3>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p [style.color]="text_color" style="margin:0px;font-size: 12px;text-align:left;line-height: 17px;"><b>Age:</b> {{data.age}}  </p>
                            <p [style.color]="text_color" style="margin:0px;font-size: 12px;text-align:left;line-height: 17px;"><b>Test:</b>
                                  <ng-container *ngIf="report_type=='by_criteria' else testName">
                                  {{performance_set_details.performance_set_name}}
                                  </ng-container>
                                  <ng-template #testName>
                                    <ng-container *ngFor="let data of performance_set_details;let i=index">
                                      <ng-container *ngIf="performance_set_details.length==1">
                                        {{data.performance_set_name}}
                                      </ng-container>
                                      <ng-container *ngIf="performance_set_details.length>1 && performance_set_details.length!=(i+1)">
                                        {{data.performance_set_name}},
                                      </ng-container>
                                      <ng-container *ngIf="performance_set_details.length>1 && performance_set_details.length==(i+1)">
                                        {{data.performance_set_name}}
                                      </ng-container>                                      
                                    </ng-container>
                                  </ng-template>
                            </p>
                            <p [style.color]="text_color" style="margin:0px;font-size: 12px;text-align:left;line-height: 17px;"><b>Gender:</b> {{data.gender}}</p>
                            <p [style.color]="text_color" style="margin:0px;font-size: 12px;text-align:left;line-height: 17px;"><b>Test Date:</b> 
                              <ng-container *ngIf="report_type=='by_criteria' else testDate">
                                {{performance_set_details.performance_set_date}}
                              </ng-container>
                              <ng-template #testDate>
                                <ng-container *ngFor="let data of performance_set_details;let i=index">
                                  <ng-container *ngIf="i==0">
                                    {{data.performance_set_date}}
                                  </ng-container>                                                        
                                </ng-container>
                              </ng-template>                              
                            </p>
                          </td>
                        </tr>
                        
                  </table>
                </td>
                
              </tr>
          </table>
          <ng-container *ngIf="report_type=='by_criteria'">            
          <table border="0" cellpadding="5" cellspacing="5" width="100%" style="margin: 0px 0px 0px 0px; table-layout:fixed;" >
              <tr>
                <ng-container *ngFor="let data of default_report_score;let i=index">
                  <ng-container *ngFor="let score of data;let i=index">
                    <td align="left" valign="top" class="textContent" style="padding:1.5px;" *ngIf="score.parameter_score_compare_criteria_key=='height'">
                      <div class="cards" style="display:block; margin:0px; padding:3px 0px; background:#1b8783; color:#fff; border-radius:8px;" [style.box-shadow]="box_shadow"> 
                        <p><img src="assets/images/height-w.png" width="40" style="width:40px; margin:auto;"/></p>
                        <p style="text-align:center; font-size:26px; margin:13px 0px 4px 0px; color:#fff;"><b>{{score.score}}</b> <small style="font-size: 65%;"> {{score.score_unit}}</small></p>
                        <p style="text-align:center; font-size:14px; margin:8px 0px; color:#fff; line-height: 20px;">Height</p>  
                      </div>
                    </td>
                    <td align="left" valign="top" class="textContent" style="padding:1.5px;" *ngIf="score.parameter_score_compare_criteria_key=='weight'">
                      <div class="cards" style="display:block; margin:0px; padding:3px 0px; background:#197592; color:#fff; border-radius:8px;" [style.box-shadow]="box_shadow">
                          <p><img src="assets/images/dumbbell-w.png" width="40" style="width:40px; margin:auto;"/></p>
                          <p style="text-align:center; font-size:26px; margin:13px 0px 4px 0px; color:#fff;"><b>{{score.score}}</b> <small style="font-size: 65%;"> {{score.score_unit}}</small></p>
                          <p style="text-align:center; font-size:14px; margin:8px 0px; color:#fff; line-height: 20px;">Weight</p>
                      </div>
                    </td>
                    <td align="left" valign="top" class="textContent" style="padding:1.5px;" *ngIf="score.parameter_score_compare_criteria_key=='bmi'">
                      <div class="cards" style="display:block; margin:0px; padding:3px 0px; background:#a87516; color:#fff; border-radius:8px;" [style.box-shadow]="box_shadow">
                            <p style="text-align:center"><img src="assets/images/bmi.png" width="40" style="width:40px; margin:auto; "/></p>  
                            <p style="text-align:center; font-size:26px; margin:13px 0px 4px 0px; color:#fff;"><b>{{score.score}}</b> <small style="font-size: 65%;"> {{score.score_unit}}</small></p>
                            <p style="text-align:center; font-size:14px; margin:8px 0px; color:#fff; line-height: 20px;">BMI</p>
                      </div>
                    </td>
                      </ng-container> 
                </ng-container>
                  
              </tr>
            </table>
          </ng-container>     
          
          <ng-container *ngIf="report_type=='default'">
            
              <ng-container *ngFor="let data of performance_set_details; let i=index">
                <h3 [style.color]="report_header" style="text-align:center; margin: 2px 0px; text-transform:uppercase; font-size:17px;font-weight:bold;">Test Description </h3>
                <table border="0" cellpadding="30" cellspacing="0" width="100%" [style.background]="card_color" style="margin: 14px 0px; border-radius:8px;" [style.box-shadow]="box_shadow">
                    <!-- <tr>
                      <td valign="top" class="textContent"  style="padding:10px;">
                          <h3 [style.color]="report_header" style="text-align:center; margin: 2px 0px; text-transform:uppercase; font-size:17px;font-weight:bold;">Test Description </h3>
                      </td>
                    </tr> -->
                    <tr>
                        <td valign="top" class="textContent" style="padding:12px 12px 12px 12px;">
                          <p [style.color]="text_color" style=" font-size:14px;text-align:center; margin:0px; line-height: 22px;" > 
                              {{data.performance_set_description}}
                          </p> 
                        </td>
                    </tr>
                </table>
                <table border="0" cellpadding="30" cellspacing="0" width="100%" [style.background]="card_color" style="margin: 14px 0px; border-radius:8px;display: none;" [style.box-shadow]="box_shadow" *ngIf="ranking!='NA' && i==0 && organization_id!='2393'">
                  <tr>
                    <td rowspan="2" valign="top" class="textContent" style="padding:10px;">
                      <img src="assets/images/badge.png" style="border-radius: 6px; width:155px; margin: 0px auto;"/>
                  </td>
                  <td rowspan="2" valign="top" class="textContent" style="width: 33%;" *ngIf="performance_set_details">
                    <Span [style.color]="text_color" style="font-size:15px;text-align:center; margin:0px; line-height: 22px;">
                      <b>OVERALL RANK </b> <br><b style="font-size:27px;">{{ranking}}</b>/<b  style="font-size:15px;">{{player_count}}</b>
                    </Span>  
                  </td>
                  <td rowspan="2" valign="top" class="textContent" style="width: 33%;" *ngIf="performance_set_details">
                    <span [style.color]="text_color" style="font-size:15px;text-align:center; margin:0px; line-height: 22px;">
                  
                      <b> PERCENTILE </b> <br>
                      <b style="font-size:27px;"> {{percentile|number:'1.0-0'}}%</b> 
                    </span>  
                  </td>
                  </tr>
                
              </table>
    
          
              <table border="0" cellpadding="0" cellspacing="0" width="100%" id="emailBody">
                <ng-container >                  
                  <tr>
                    <td>
                      <h3
                        style="text-align:center; margin: 25px 0px 16px 0px; letter-spacing: 1px; text-transform:uppercase; font-size:17px; color:#ffffff; font-weight:bold;">
                        <span
                          style="background:#f59501; padding:10px 20px; border-radius:35px;">Parameters</span>
                      </h3>
                    </td>
                  </tr>
                </ng-container>  
              </table> 
              <ng-container *ngFor="let data of default_report_score;let i=index">
                
                <table  border="0" cellpadding="10" class="adjust-padding-table" cellspacing="0" width="100%" style="margin: 5px 0px 5px 0px; border-radius:8px;" [style.box-shadow]="box_shadow" [style.background]="card_color">
                  <ng-container *ngFor="let score of data;let i=index">
                  <tr >
                      <td *ngIf="i==0" valign="top" rowspan="50" width="100" bgcolor="#67D9DE" class="textContent row-span-td-width" style="width:100px; border-top-left-radius:8px;border-bottom-left-radius:8px">
                        <h3 style="margin-top:10px; margin-bottom:1px; text-align:left; letter-spacing: 1px; text-transform:capitalize; font-size:11px; color:#fff; font-weight:normal; word-break:break-word;">{{score.performance_area_name}}</h3>
                        <p style="margin:3px 0px; text-align:left; letter-spacing: 1px; text-transform:capitalize; font-size:15px; color:#fff; font-weight:bold !important; word-break:break-word;">{{score.performance_parameter_name}}</p>
                        <!-- <p style="line-height:11px !important; margin:0px; font-style: italic; font-size:10px;color:#fff;" *ngIf="score.score_unit!='' else noUnit">{{score.score_label}} in {{score.score_unit}}</p>
                        <ng-template #noUnit>
                          <p style="line-height:11px !important; margin:0px; font-style: italic; font-size:10px;color:#fff;">{{score.score_label}}</p>
                        </ng-template> -->
                      </td>                  
                      <td colspan="2" valign="top" class="textContent" style="border-bottom: 1px solid " [style.color]="bottom_border" *ngIf="score.is_allow_show_desc!='0' && score.is_allow_show_desc!=''">
                        <span style="margin:0px 0px; font-size:13px; "
                                                      [style.color]="text_color">
                                                      <b>Objective: </b></span>
                                                      <div [innerHTML]="score.desc | linky"  style="display: inline-block;margin:0px 0px; font-size:13px; "
                                                    [style.color]="text_color"></div>
                      </td>
                   
                    </tr>  
                    <tr>
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px;">
                        <ng-container *ngIf="score.file_type=='VIDEO' else noVideo">
                        
                        </ng-container>
                        <ng-template #noVideo>
                          <span style="margin:0px 0px; font-size:13px; "
                          [style.color]="text_color" *ngIf="score.score_unit!='' else noUnit">
                          <b><i class="fa fa-circle" style="font-size: 10px;" aria-hidden="true"></i> {{score.score_label}} in {{score.score_unit}}</b></span>
                        
                          <ng-template #noUnit>
                            <span style="margin:0px 0px; font-size:13px; "
                              [style.color]="text_color" >
                              <b><i class="fa fa-circle" style="font-size: 10px;" aria-hidden="true"></i> {{score.score_label}}</b></span>
                            
                          </ng-template>
                        </ng-template>
                       
                      </td> 
                    </tr>                  
                    
                    <tr *ngIf="score.score_type_name=='Numeric'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                            <ng-container *ngIf="score.score>=score.better_score && score.score>=score.batch_avg_score else num2">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                              </div>
                              </ng-container>
                              <ng-template #num2>
                              <ng-container *ngIf="score.better_score>score.score && score.better_score>score.batch_avg_score else num3">
                                <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.better_score+'%'" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                </div>
                              </ng-container>
                              <ng-template #num3>
                                <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                </div>
                              </ng-template>
                              </ng-template>                                                               
                            </div>
                          </div>
                        </div>
                        </div>
                      </td>
                    </tr>                                                       
                      
                    <tr *ngIf="score.score_type_name=='Numeric'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                            <ng-container *ngIf="score.better_score>=score.score && score.better_score>=score.batch_avg_score else numoptimum2">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                              <span style="padding-right: 4px;"> Optimum : {{score.better_score}}</span>
                              </div>
                            </ng-container>
                            <ng-template #numoptimum2>
                              <ng-container *ngIf="score.score>score.better_score && score.score>score.batch_avg_score else numoptimum3">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.better_score/score.score+'%'" >
                                <span style="padding-right: 4px;"> Optimum : {{score.better_score}}</span>
                              </div>
                              </ng-container>
                              <ng-template #numoptimum3>
                              <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.better_score/score.batch_avg_score+'%'" >
                                <span style="padding-right: 4px;"> Optimum : {{score.better_score}}</span>
                              </div>
                              </ng-template>
                            </ng-template>                                                              
                            </div>
                          </div>
                        </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="score.score_type_name=='Numeric'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;margin-bottom: 0px !important;">                                                         
                            <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.better_score else numavg2">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                              </div>
                            </ng-container>
                            <ng-template #numavg2>
                              <ng-container *ngIf="score.score>score.better_score && score.score>score.batch_avg_score else numavg3">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                              </div>
                              </ng-container>
                              <ng-template #numavg3>
                              <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.better_score+'%'" >
                                <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                              </div>
                              </ng-template>
                            </ng-template>                                                              
                            </div>
                          </div>
                        </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="score.score_type_name=='Text'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 7px 10px;">
                        <p><b>{{score.score}}</b> </p>
                      </td>
                    </tr>
                    <tr  *ngIf="score.score_type_name=='Rating'">
                      <td colspan="2" valign="top" class="textContent">
                        <p style="margin-top: 5px; font-size:17px;margin-bottom: -13px;text-align: center;" >
                          <span style="font-weight: 600;color:#f59501">
                            <!-- {{score.score_label}} :  -->
                            <ng-container *ngFor="let data of score.rating_details; let i=index">
                              <ng-container *ngIf="score.score==data.id">{{data.value}}</ng-container>
                            </ng-container>
                            </span>
                        </p>
                      </td>
                    </tr>
                    <tr  *ngIf="score.score_type_name=='Rating'">                                                       
                      <td colspan="2" valign="top" class="textContent" >
                        <div class="bmi-stat ">
                          <ul class="listing-stars">                            
                            <ng-container *ngFor="let data of score.rating_details; let i=index">
                              <li>
                                <ng-container *ngIf="score.score!=data.id && i<score.score">
                                  <span class="fa fa-star" style="color: #f59501;"></span>
                                  <!-- <p style="color: #f59501">{{data.value}}</p> -->
                                </ng-container>
                                <ng-container *ngIf="score.score==data.id  else grayStars">
                                  <span class="fa fa-star"  style="color: #f59501;font-size:30px !important"></span>
                                  <!-- <p style="color: #f59501;font-size: 14px;">{{data.value}}</p> -->
                                </ng-container>
                                <ng-template #grayStars>
                                  <ng-container *ngIf="i>score.score">
                                    <span class="fa fa-star"
                                      style="color: gray"></span>
                                    <!-- <p>{{data.value}}</p> -->
                                  </ng-container>
                                </ng-template>
                              </li>
                            </ng-container>                            
                          </ul>
                        </div>
                      </td>                                                       
                    </tr>    
                    <tr  *ngIf="score.score_type_name=='Rating'">
                      <td  style="padding: 0px 0px;">
                        <span style="font-size:11px;color:#626262;margin-left: 20px;font-weight: 600;">Rating System</span>
                      </td>
                    </tr>
                    <ng-container  *ngIf="score.score_type_name=='Rating'">
                      <tr *ngFor="let data of score.rating_details; let i=index">
                        <td  style="padding: 0px 0px;">
                          <span style="font-size:9px;color:#808080;margin-left: 20px;">{{data.value}}</span>
                        </td>
                        <td  style="padding: 0px 0px;">
                          
                          <!-- [ngStyle]="{'color': (i<=score.score) ? '#f59501' : '#808080'}" -->
                          <span class="fa fa-star"  style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>0" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>1" style="font-size:9px;color:#808080" ></span>
                          <span class="fa fa-star" *ngIf="i>2" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>3" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>4" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>5" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>6" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>7" style="font-size:9px;color:#808080"></span>
                          <span class="fa fa-star" *ngIf="i>8" style="font-size:9px;color:#808080"></span>
                          
                        </td>
                      </tr>
                    </ng-container>                 
                    
                    <tr  *ngIf="score.score_type_name=='Rating'">
                      <td colspan="2" valign="top" class="textContent"  style="padding: 5px 10px">
                        <p style="margin:0px 0px;" [style.color]="text_color">
                          <span style="float: left;font-size: 8px;">Average Rating: {{score.batch_avg_score_grade}} ({{score.batch_avg_score|number:'1.1-1'}}/{{score.rating_details.length}}) </span>
                        </p>
                      </td>
                    </tr>
                    <tr  *ngIf="score.score_type_name=='Rating'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 5px 10px">
                        <p style="margin:0px 0px;" [style.color]="text_color">
                          <span *ngIf="score.parameter_score_compare_criteria_key!='NA'" style="font-size: 8px;">Optimum Rating:  {{score.better_score_grade}} ({{score.better_score|number:'1.1-1'}}/{{score.rating_details.length}})</span>
                        </p>
                      </td>
                    </tr>
                    <tr  *ngIf="score.score_type_name=='Stopwatch'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                          <ng-container *ngIf="score.score>=score.better_score && score.score>=score.batch_avg_score else num2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                              <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                            </div>
                          </ng-container>
                          <ng-template #num2>
                            <ng-container *ngIf="score.better_score>score.score && score.better_score>score.batch_avg_score else num3">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.better_score+'%'" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                              </div>
                            </ng-container>
                            <ng-template #num3>
                              <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                              </div>
                            </ng-template>
                          </ng-template> 
                          </div>
                        </div>
                        </div>
                        </div>
                      </td>
                    </tr> 
  
                    <tr  *ngIf="score.score_type_name=='Stopwatch'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                          <ng-container *ngIf="score.better_score>=score.score && score.better_score>=score.batch_avg_score else numoptimum2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                              <span style="padding-right: 4px;"> Optimum : {{score.better_score_mm_ss_ms}}</span>
                            </div>
                          </ng-container>
                          <ng-template #numoptimum2>
                            <ng-container *ngIf="score.score>score.better_score && score.score>score.batch_avg_score else numoptimum3">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.better_score/score.score+'%'" >
                                <span style="padding-right: 4px;"> Optimum : {{score.better_score_mm_ss_ms}}</span>
                              </div>
                            </ng-container>
                            <ng-template #numoptimum3>
                              <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.better_score/score.batch_avg_score+'%'" >
                                <span style="padding-right: 4px;"> Optimum : {{score.better_score_mm_ss_ms}}</span>
                              </div>
                            </ng-template>
                          </ng-template>
                          </div>
                        </div>
                        </div>
                        </div>
                      </td>
                    </tr>
                    <tr  *ngIf="score.score_type_name=='Stopwatch'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                      <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;margin-bottom: 0px !important;">                                                         
                                                                                
                        <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.better_score else numavg2">
                          <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                            <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                          </div>
                        </ng-container>
                        <ng-template #numavg2>
                          <ng-container *ngIf="score.score>score.better_score && score.score>score.batch_avg_score else numavg3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                            </div>
                          </ng-container>
                          <ng-template #numavg3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.better_score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                            </div>
                          </ng-template>
                        </ng-template>
                        </div>
                        </div>
                      </div>
                      </div>
                      </td>
                    </tr> 

                    <tr  *ngIf="score.score_type_name=='Dropdown'" >
                      <td colspan="2" valign="top" class="textContent" style="padding: 7px 10px;">
                        <p> {{score.score}}</p>
                      </td>
                    </tr>

                    <tr *ngIf="score.score_type_name=='Document/Image'">
                      
                      
                      <ng-container  *ngIf="score.file_type=='VIDEO'">
                        <td colspan="2" valign="top" class="textContent"
                      style="padding: 5px 10px;text-align: center;">
                        <iframe [src]='score.score' frameborder="0"  
                        style="width: 100%;height: 100%;margin-bottom: 10px;" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                        </td>
                      </ng-container>
                      <ng-container  *ngIf="score.file_type=='IMG'">
                        <td colspan="2" valign="top" class="textContent"
                      style="padding: 5px 10px;text-align: center;">
                        <img [src]='score.score' 
                        style="width: 400px;height: 300px;margin-bottom: 10px;">
                        </td>
                      </ng-container>
                      <ng-container  *ngIf="score.file_type=='DOC' || score.file_type==null">
                        <td colspan="2" valign="top" class="textContent"
                        style="padding: 18px 10px;">
                        <label style="color:#555; font-size:15px; font-weight:bold;">
                          <span
                            style="color:#555; font-size:15px; font-weight:normal;">

                            <span
                              *ngIf="score.score.substring(0,4) =='http';else tableData">
                              <span (click)="openDoc(score.score)">
                                <u>View</u>
                              </span>
                            </span>
                            <ng-template #tableData> {{score.score}}</ng-template>

                          </span></label>
                        </td>
                      </ng-container>                      
                        
                    </tr>

                    <tr>
                        <td colspan="2" valign="top" class="textContent" style="border-top: 1px solid" [style.color]="bottom_border" *ngIf="score.is_allow_show_comment=='1' && score.comments!=''">                            
                          <span style="margin:5px 0px; font-size:12px;line-height: 15px;"  [style.color]="text_color"><b>Observation: </b></span>
                          <div [innerHTML]="score.comments | linky"  style="display: inline-block;margin:0px 0px; font-size:12px;" [style.color]="text_color"></div>
                        </td>
                    </tr> 
               </ng-container>   
                </table>
              </ng-container> 
          </ng-container>               
          </ng-container>     
          <ng-container *ngIf="report_type=='by_criteria'"> 
            <h3 [style.color]="report_header" style="text-align:center; margin: 2px 0px; text-transform:uppercase; font-size:15px; font-weight:bold;">Test Description </h3>
            <table border="0" cellpadding="30" cellspacing="0" width="100%" class="adjust-padding-table" [style.background]="card_color" style="margin: 14px 0px; border-radius:8px;" [style.box-shadow]="box_shadow">
                <!-- <tr>
                  <td valign="top" class="textContent"  style="padding:10px;">
                      <h3 [style.color]="report_header" style="text-align:center; margin: 2px 0px; text-transform:uppercase; font-size:15px; font-weight:bold;">Test Description </h3>
                  </td>
                </tr> -->
                <tr>
                    <td valign="top" class="textContent" style="padding:12px 12px 12px 12px;" *ngIf="performance_set_details">
                      <p [style.color]="text_color" style=" font-size:14px; text-align:center; margin:0px; line-height: 22px;" > 
                          {{performance_set_details.performance_set_description}}
                      </p> 
                  </td>
                </tr>
            </table>
            <table border="0" cellpadding="30" cellspacing="0" width="100%" [style.background]="card_color" style="margin: 14px 0px; border-radius:8px;display: none;" [style.box-shadow]="box_shadow" *ngIf="ranking!='NA' && organization_id!='2393'">
              <tr>
                <td rowspan="2" valign="top" class="textContent" style="padding:10px;">
                  <img src="assets/images/badge.png" style="border-radius: 6px; width:155px; margin: 0px auto;"/>
              </td>
              <td rowspan="2" valign="top" class="textContent" style="width: 33%;" *ngIf="performance_set_details">
                <Span [style.color]="text_color" style="font-size:15px;text-align:center; margin:0px; line-height: 22px;">
                  <b>OVERALL RANK </b> <br><b style="font-size:27px;">{{ranking}}</b>/<b  style="font-size:15px;">{{player_count}}</b>
                </Span>  
              </td>
              <td rowspan="2" valign="top" class="textContent" style="width: 33%;" *ngIf="performance_set_details">
                <span [style.color]="text_color" style="font-size:15px;text-align:center; margin:0px; line-height: 22px;">
                  <b> PERCENTILE </b> <br>
                  <b style="font-size:27px;"> {{percentile|number:'1.0-0'}}%</b>  
                </span>  
              </td>
              </tr>
            
          </table>

      
          <table border="0" cellpadding="0" cellspacing="0" width="100%" id="emailBody">
            <ng-container >                  
              <tr>
                <td>
                  <h3
                    style="text-align:center; margin: 25px 0px 16px 0px; letter-spacing: 1px; text-transform:uppercase; font-size:17px; color:#ffffff; font-weight:bold;">
                    <span
                      style="background:#f59501; padding:10px 20px; border-radius:35px;">Parameters</span>
                  </h3>
                </td>
              </tr>
            </ng-container>  
          </table> 
            <ng-container *ngFor="let data of default_report_score;let j=index">  
                        
              <table border="0" cellpadding="10" cellspacing="0" width="100%" class="adjust-padding-table" [style.background]="card_color" style="margin: 5px 0px 5px 0px; border-radius:8px;" [style.box-shadow]="box_shadow">
                <ng-container *ngFor="let score of data;let i=index"> 
                  <ng-container *ngIf="score.is_display_parameter==1 && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                  <tr>
                    <td valign="top" *ngIf="i==0" rowspan="50" width="100" bgcolor="#67D9DE" class="textContent row-span-td-width" style="width:100px; border-top-left-radius:8px;border-bottom-left-radius:8px ">
                      <h3 style="margin-top:10px; margin-bottom:1px; text-align:left; letter-spacing: 1px; text-transform:capitalize; font-size:11px; color:#fff; font-weight:normal; word-break:break-word;">{{score.performance_area_name}}</h3>
                      <p style="margin:3px 0px; text-align:left; letter-spacing: 1px; text-transform:capitalize; font-size:15px; color:#fff; font-weight:bold !important; word-break:break-word;">{{score.performance_parameter_name}}</p>
                      <!-- <p style="line-height:11px !important; margin:0px; font-style: italic; font-size:10px;color:#fff;" *ngIf="score.score_unit!='' else noUnit">{{score.score_label}} in {{score.score_unit}}</p>
                      <ng-template #noUnit>
                        <p style="line-height:11px !important; margin:0px; font-style: italic; font-size:10px;color:#fff;">{{score.score_label}}</p>
                      </ng-template> -->
                    </td>                  
                    <td colspan="2" valign="top" class="textContent" style="border-bottom: 1px solid" [style.color]="bottom_border" *ngIf="score.is_allow_show_desc!='0' && score.is_allow_show_desc!=''">
                      <span style="margin:0px 0px; font-size:13px; "
                                                    [style.color]="text_color">
                                                    <b>Objective: </b></span>
                                                    <div [innerHTML]="score.desc | linky"  style="display: inline-block;margin:0px;margin-bottom: 15px; font-size:13px; "
                                                  [style.color]="text_color"></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px;">
                      <ng-container *ngIf="score.file_type=='VIDEO' else noVideo">
                      
                      </ng-container>
                      <ng-template #noVideo>
                        <span style="margin:0px 0px; font-size:13px; "
                        [style.color]="text_color" *ngIf="score.score_unit!='' else noUnit">
                        <b><i class="fa fa-circle" style="font-size: 10px;" aria-hidden="true"></i> {{score.score_label}} in {{score.score_unit}}</b></span>
                      
                        <ng-template #noUnit>
                          <span style="margin:0px 0px; font-size:13px; "
                            [style.color]="text_color" >
                            <b><i class="fa fa-circle" style="font-size: 10px;" aria-hidden="true"></i> {{score.score_label}}</b></span>
                          
                        </ng-template>
                      </ng-template>
                     
                    </td> 
                  </tr> 
                  <tr *ngIf="score.score_type_name=='Numeric' && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                                <ng-container *ngIf="score.score>=score.benchmark_score && score.score>=score.batch_avg_score else num2">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num2>
                                    <ng-container *ngIf="score.benchmark_score>score.score && score.benchmark_score>score.batch_avg_score else num3">
                                      <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.benchmark_score+'%'" >
                                        <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                      </div>
                                    </ng-container>
                                    <ng-template #num3>
                                      <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                        <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                      </div>
                                    </ng-template>
                                  </ng-template>                                                               
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.batch_highest_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.batch_highest_score>score.score && score.batch_highest_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_highest_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.avg_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.avg_score>score.score && score.avg_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.batch_lowest_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.batch_lowest_score>score.score && score.batch_lowest_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_lowest_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Numeric' && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                        <!-- new design -->
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">                                                      
                                <ng-container *ngIf="score.benchmark_score>=score.score && score.benchmark_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.benchmark_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.benchmark_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.benchmark_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.batch_highest_score>=score.score && score.batch_highest_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.batch_highest_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_highest_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_highest_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.avg_score>=score.score && score.avg_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.avg_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.avg_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.avg_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.avg_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.avg_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.batch_lowest_score>=score.score && score.batch_lowest_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.batch_lowest_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_lowest_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_lowest_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- new design end -->
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Numeric' && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                        <!-- new design -->
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.benchmark_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.benchmark_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.benchmark_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.batch_highest_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.batch_highest_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.batch_highest_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.avg_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.avg_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.batch_lowest_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.batch_lowest_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.batch_lowest_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- new design end -->
                      </div>
                    </td>
                  </tr> 
                  <ng-container *ngIf="score.score_type_name=='Numeric' && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                    <tr *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 9px 10px 5px 10px;">
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px;" *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;padding-top: 15px;">
                            <div class="progress" style="margin-bottom:0px;background:transparent; height:0px;">
                              <ng-container *ngIf="score.score>score.max_score1">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="83+'%'" style="background:transparent; float:left;position: relative;">
                                  <div class="tooltip top right tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="right: 0%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container> 
                              <ng-container *ngIf="score.score==score.max_score1">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="2*100/3+'%'" style="background:transparent; float:left;position: relative;">
                                  <div class="tooltip top right tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="right: 0%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="score.score<score.max_score1 && score.score>score.min_score1 ">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="(score.score*(2*100/3))/score.max_score1+'%'" style="background:transparent; float:left;position: relative;">
                                  <div class="tooltip top right tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="right: 0%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container> 
                              <ng-container *ngIf="score.score<score.min_score1 ">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="(score.score*(100/3))/score.min_score1+'%'" style="background:transparent; float:left;position: relative;">
                                  <div class="tooltip top right tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="right: 0%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container> 
                              <ng-container *ngIf="score.score==score.min_score1 ">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="100/3+'%'" style="background:transparent; float:left;position: relative;">
                                  <div class="tooltip top right tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="right: 0%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container> 
                              <!-- <ng-container *ngIf="score.max_score1>100 && score.max_score1>score.score">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="100*score.score/score.max_score1+'%'" style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container> 
                              <ng-container *ngIf="score.max_score1>100 && score.max_score1<score.score">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="100*score.score/score.score+'%'" style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>                                                            
                              <ng-container *ngIf="score.max_score1<101 && score.max_score1>score.score && score.max_score1>10">
                                <div class="progress-bar progress-bar-striped"  role="progressbar" [style.width]="score.score+'%'"  style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="score.max_score1<101 && score.score>score.max_score1">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="100*score.score/score.score+'%'" style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="score.max_score1<101 && score.max_score1>score.score && score.max_score1<11">
                                <div class="progress-bar progress-bar-striped"  role="progressbar" [style.width]="(score.score+5)+'%'"  style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="score.max_score1==score.score">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width]="100*score.score/score.score+'%'" style="background:transparent; float:left;">
                                  <div class="tooltip top left tripple-tooltip" role="tooltip" style="display: block; float:right;">
                                    <div class="tooltip-arrow" style="left: 1%;"></div>
                                    <div class="tooltip-inner">{{score.score}}</div>
                                  </div>
                                </div>
                              </ng-container>  -->
                            </div>
                          </div>
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'">
                              <ng-container >
                                <div class="progress" style="float:left; width:100%; border-radius:9px;">                                                              
                                  <div class="progress-bar progress-bar-striped"  role="progressbar" style="border-top-left-radius:9px; border-bottom-left-radius:9px;" [style.width]="100/3+'%'" [style.background]="score.less_than_min_score_color">
                                    <span style="float:left; position:relative; bottom:-22px; color:#000;text-align: left;">0</span>                                                                  
                                  </div>
                                  <div class="progress-bar progress-bar-striped"  role="progressbar" [style.width]="100/3+'%'" [style.background]="score.in_between_min_and_max_score_color">
                                    <span style="float:left; position:relative; bottom:-22px; color:#000; transform: translateX(0px);text-align: left;">{{score.min_score1}}</span>
                                  </div>
                                  <div class="progress-bar progress-bar-striped" role="progressbar" style="border-top-right-radius:9px; border-bottom-right-radius:9px;" [style.width]="100/3+'%'" [style.background]="score.greater_than_max_score_color">
                                    <span style="float:left; position:relative; bottom:-22px; color:#000; transform: translateX(0px);text-align: left;">{{score.max_score1}}</span>
                                  </div>
                                </div>
                              </ng-container>
                              
                              <div>
                                <ul class="legends-bm">
                                  <li style="padding-left:0px;" *ngIf="score.min_score1>score.score"><span
                                      [style.background]="score.less_than_min_score_color"></span>
                                    {{score.less_than_min_score_label}}</li>
                                  <li *ngIf="score.score>score.min_score1 && score.score<score.max_score1"><span
                                      [style.background]="score.in_between_min_and_max_score_color" ></span>
                                    {{score.in_between_min_and_max_score_label}}</li>
                                  <li *ngIf="score.score>score.max_score1"><span
                                      [style.background]="score.greater_than_max_score_color"></span>
                                    {{score.greater_than_max_score_label}}</li>
                                </ul>
                              </div>
                            </ng-container>
                          </div>
                        </div> 
                      </td>
                    </tr>
                  </ng-container>                    
                  <!-- Benchmark score range -->                   
                  <!-- NA score type -->
                  <ng-container *ngIf="score.score_type_name=='Numeric' && score.parameter_score_compare_criteria_key!='height' && score.parameter_score_compare_criteria_key!='weight' && score.parameter_score_compare_criteria_key!='bmi'">
                    <tr *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                      <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                        <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; " *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                          <ng-container *ngIf="score.score>=score.max_score && score.score>=score.batch_avg_score else num2">
                          <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                            <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                          </div>
                          </ng-container>
                          <ng-template #num2>
                          <ng-container *ngIf="score.max_score>score.score && score.max_score>score.batch_avg_score else num3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.max_score+'%'" >
                            <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                            </div>
                          </ng-container>
                          <ng-template #num3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                            <span style="padding-right: 4px;"> Your Score : {{score.score}}</span>
                            </div>
                          </ng-template>
                          </ng-template>                                                               
                          </div>
                        </div>
                        </div>
                        </div>
                      </td>
                      </tr> 
                      <tr *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                        <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                          <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                          <div class="uk-grid" style="margin-top:0px;margin-left:0px; " *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                            <ng-container *ngIf="score.max_score>=score.score && score.max_score>=score.batch_avg_score else numoptimum2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                            <span style="padding-right: 4px;"> Optimum : {{score.max_score}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numoptimum2>
                            <ng-container *ngIf="score.score>score.max_score && score.score>score.batch_avg_score else numoptimum3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.max_score/score.score+'%'" >
                              <span style="padding-right: 4px;"> Optimum : {{score.max_score}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numoptimum3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.max_score/score.batch_avg_score+'%'" >
                              <span style="padding-right: 4px;"> Optimum : {{score.max_score}}</span>
                            </div>
                            </ng-template>
                            </ng-template>                                                              
                            </div>
                          </div>
                          </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                        <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                          <div class="progress"  style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                          <div class="uk-grid" style="margin-top:0px;margin-left:0px; " *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;margin-bottom: 0px !important;">                                                         
                            <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.max_score else numavg2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;">
                            <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numavg2>
                            <ng-container *ngIf="score.score>score.max_score && score.score>score.batch_avg_score else numavg3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numavg3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.max_score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score}}</span>
                            </div>
                            </ng-template>
                            </ng-template>                                                              
                            </div>
                          </div>
                          </div>
                          </div>
                        </td>
                      </tr>
                  </ng-container>                      
                  <!-- NA score type --> 

                  <tr *ngIf="score.score_type_name=='Rating'">
                    <td colspan="2" valign="top" class="textContent">
                      <p style="margin-top: 5px; font-size:17px;margin-bottom: -13px;text-align: center;" >
                        <span style="font-weight: 600;color:#f59501">
                          <!-- {{score.score_label}} :  -->
                          <ng-container *ngFor="let data of score.rating_details; let i=index">
                            <ng-container *ngIf="score.score==data.id">{{data.value}}</ng-container>
                          </ng-container>
                          </span>
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Rating'">                                                       
                    <td colspan="2" valign="top" class="textContent" style="padding: 5px 10px;">
                      <div class="bmi-stat " style="margin-bottom:10px;">
                        <ul class="listing-stars">
                          
                          <ng-container *ngFor="let data of score.rating_details; let i=index">
                            <li>
                              <ng-container *ngIf="score.score!=data.id && i<score.score">
                                <span class="fa fa-star" style="color: #f59501;"></span>
                                <!-- <p style="color: #f59501">{{data.value}}</p> -->
                              </ng-container>
                              <ng-container *ngIf="score.score==data.id  else grayStars">
                                <span class="fa fa-star"  style="color: #f59501;font-size:30px !important"></span>
                                <!-- <p style="color: #f59501;font-size: 14px;">{{data.value}}</p> -->
                              </ng-container>
                              <ng-template #grayStars>
                                <ng-container *ngIf="i>score.score">
                                  <span class="fa fa-star"
                                    style="color: gray"></span>
                                  <!-- <p>{{data.value}}</p> -->
                                </ng-container>
                              </ng-template>
                            </li>
                          </ng-container>                                                         
                        </ul>
                      </div>
                    </td>                                                       
                  </tr>

                  <tr *ngIf="score.score_type_name=='Rating'">
                    <td  style="padding: 0px 0px;">
                      <span style="font-size:11px;color:#626262;margin-left: 20px;font-weight: 600;">Rating System</span>
                    </td>
                  </tr>
                  <ng-container *ngIf="score.score_type_name=='Rating'">
                    <tr *ngFor="let data of score.rating_details; let i=index">
                      <td  style="padding: 0px 0px;">
                        <span style="font-size:9px;color:#808080;margin-left: 20px;">{{data.value}}</span>
                      </td>
                      <td  style="padding: 0px 0px;">
                        
                        <!-- [ngStyle]="{'color': (i<=score.score) ? '#f59501' : '#808080'}" -->
                        <span class="fa fa-star"  style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>0" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>1" style="font-size:9px;color:#808080" ></span>
                        <span class="fa fa-star" *ngIf="i>2" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>3" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>4" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>5" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>6" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>7" style="font-size:9px;color:#808080"></span>
                        <span class="fa fa-star" *ngIf="i>8" style="font-size:9px;color:#808080"></span>
                        
                      </td>
                    </tr>
                  </ng-container>
                  
                  <tr *ngIf="score.score_type_name=='Rating'">
                    <td colspan="2" valign="top" class="textContent">
                      <p style="margin:0px 0px;" [style.color]="text_color">
                        <span style="float: left;font-size: 8px;">Average Rating: {{score.batch_avg_score_grade}} ({{score.batch_avg_score|number:'1.1-1'}}/{{score.rating_details.length}}) </span>
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Rating'">
                    <td colspan="2" valign="top" class="textContent">
                      <p style="margin:0px 0px;" [style.color]="text_color">
                        <span  *ngIf="score.parameter_score_compare_criteria_key!='NA'" style="font-size: 8px;">Optimum Rating:  {{score.better_score_grade}} ({{score.better_score|number:'1.1-1'}}/{{score.rating_details.length}})</span>
                      </p>
                    </td>
                  </tr>

                  <tr *ngIf="score.score_type_name=='Text'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 7px 10px;">
                      <p [style.color]="text_color"><b>{{score.score}}</b> </p>
                    </td>
                  </tr>

                  <tr *ngIf="score.score_type_name=='Document/Image'">

                    <ng-container  *ngIf="score.file_type=='VIDEO'">
                      <td colspan="2" valign="top" class="textContent"
                    style="padding: 5px 10px;text-align: center;">
                      <iframe [src]='score.score' frameborder="0"  
                      style="width: 100%;height: 100%;margin-bottom: 10px;" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                      </td>
                    </ng-container>
                    <ng-container  *ngIf="score.file_type=='IMG'">
                      <td colspan="2" valign="top" class="textContent"
                    style="padding: 5px 10px;text-align: center;">
                      <img [src]='score.score' 
                      style="width: 400px;height: 300px;margin-bottom: 10px;">
                      </td>
                    </ng-container>
                    <ng-container  *ngIf="score.file_type=='DOC' || score.file_type==null">
                      <td colspan="2" valign="top" class="textContent"
                      style="padding: 18px 10px;">
                      <label style="color:#555; font-size:15px; font-weight:bold;">
                        <span
                          style="color:#555; font-size:15px; font-weight:normal;">

                          <span
                            *ngIf="score.score.substring(0,4) =='http';else tableData">
                            <span (click)="openDoc(score.score)">
                              <u>View</u>
                            </span>
                          </span>
                          <ng-template #tableData> {{score.score}}</ng-template>

                        </span></label>
                      </td>
                    </ng-container>                     
                  </tr>

                  <tr *ngIf="score.score_type_name=='Dropdown'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 18px 10px;">
                      <p> {{score.score}}</p>
                    </td> 
                  </tr>

                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                                <ng-container *ngIf="score.score>=score.benchmark_score && score.score>=score.batch_avg_score else num2">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num2>
                                    <ng-container *ngIf="score.benchmark_score>score.score && score.benchmark_score>score.batch_avg_score else num3">
                                      <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.benchmark_score+'%'" >
                                        <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                      </div>
                                    </ng-container>
                                    <ng-template #num3>
                                      <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                        <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                      </div>
                                    </ng-template>
                                  </ng-template>                                                               
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.batch_highest_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.batch_highest_score>score.score && score.batch_highest_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_highest_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.avg_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.avg_score>score.score && score.avg_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'">                                                             
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">
                                <ng-container *ngIf="score.score>=score.batch_lowest_score && score.score>=score.batch_avg_score else num2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                    <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #num2>
                                  <ng-container *ngIf="score.batch_lowest_score>score.score && score.batch_lowest_score>score.batch_avg_score else num3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_lowest_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #num3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                        <!-- new design -->
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">                                                      
                                <ng-container *ngIf="score.benchmark_score>=score.score && score.benchmark_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.benchmark_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.benchmark_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.benchmark_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.benchmark_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.batch_highest_score>=score.score && score.batch_highest_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.batch_highest_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_highest_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_highest_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_highest_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.avg_score>=score.score && score.avg_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.avg_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.avg_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.avg_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'">
                              <div class="progress left-side-progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                                <ng-container *ngIf="score.batch_lowest_score>=score.score && score.batch_lowest_score>=score.batch_avg_score else numoptimum2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numoptimum2>
                                  <ng-container *ngIf="score.score>score.batch_lowest_score && score.score>score.batch_avg_score else numoptimum3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_lowest_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numoptimum3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_lowest_score/score.batch_avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Optimum : {{score.batch_lowest_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- new design end -->
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key!='benchmark_score_range' && score.parameter_score_compare_criteria_key!='NA'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;">
                        <!-- new design -->
                        <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                          <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.benchmark_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.benchmark_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.benchmark_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='highest_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.batch_highest_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.batch_highest_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.batch_highest_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='closer_to_average_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.avg_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.avg_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.avg_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="score.parameter_score_compare_criteria_key=='lowest_in_batch'"> 
                              <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 18px !important;margin-bottom: 0px !important;">
                                <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.batch_lowest_score else numavg2">
                                  <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                    <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                  </div>
                                </ng-container>
                                <ng-template #numavg2>
                                  <ng-container *ngIf="score.score>score.batch_lowest_score && score.score>score.batch_avg_score else numavg3">
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-container>
                                  <ng-template #numavg3>
                                    <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.batch_lowest_score+'%'" >
                                      <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- new design end -->
                      </div>
                    </td>
                  </tr>             
                  <!-- Benchmark score range -->
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                      <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                          <ng-container *ngIf="score.score>=score.max_score && score.score>=score.batch_avg_score else num2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                              <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                            </div>
                            </ng-container>
                            <ng-template #num2>
                            <ng-container *ngIf="score.max_score>score.score && score.max_score>score.batch_avg_score else num3">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.better_score+'%'" >
                              <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                              </div>
                            </ng-container>
                            <ng-template #num3>
                              <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.batch_avg_score+'%'" >
                              <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                              </div>
                            </ng-template>
                            </ng-template>                                                               
                          </div>
                        </div>
                      </div>
                      </div>
                    </td>
                  </tr> 
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress"  *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                      <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                          <ng-container *ngIf="score.max_score>=score.score && score.max_score>=score.batch_avg_score else numoptimum2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                            <span style="padding-right: 4px;"> Max : {{score.max_score_mm_ss_ms}}</span>
                            </div>
                          </ng-container>
                          <ng-template #numoptimum2>
                            <ng-container *ngIf="score.score>score.max_score && score.score>score.batch_avg_score else numoptimum3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.max_score/score.score+'%'" >
                              <span style="padding-right: 4px;"> Max : {{score.max_score_mm_ss_ms}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numoptimum3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.max_score/score.batch_avg_score+'%'" >
                              <span style="padding-right: 4px;"> Max : {{score.max_score_mm_ss_ms}}</span>
                            </div>
                            </ng-template>
                          </ng-template>                                                              
                          </div>
                        </div>
                      </div>
                      </div>
                    </td>
                  </tr>                                                    
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 5px 10px;">
                      <div class="progress" *ngIf="score.parameter_score_compare_criteria_key=='benchmark_score_range'" style="margin-bottom:4px;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                     
                      <div class="uk-grid" style="margin-top:0px;margin-left:0px; ">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;margin-bottom: 0px !important;">                                                         
                          <ng-container *ngIf="score.batch_avg_score>=score.score && score.batch_avg_score>=score.max_score else numavg2">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                            <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                            </div>
                          </ng-container>
                          <ng-template #numavg2>
                            <ng-container *ngIf="score.score>score.max_score && score.score>score.batch_avg_score else numavg3">
                            <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                            </div>
                            </ng-container>
                            <ng-template #numavg3>
                            <div class="progress-bar progress-bar-striped" style="text-align:right;float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.max_score+'%'" >
                              <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                            </div>
                            </ng-template>
                          </ng-template>                                                              
                          </div>
                        </div>
                      </div>
                      </div>
                    </td>
                  </tr> 
                  
                  <!-- Benchmark score range -->  
                  <!-- NA score type -->
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                  <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 0px 10px;">
                    <div class="uk-grid" style="margin-top:0px;margin-left:0px;" *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                      <div class="uk-width-1-1" style="margin:0px; padding:0px;border-left: 1px solid gray;">
                        <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                          <ng-container *ngIf="score.score>=score.batch_avg_score else num2">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" >
                                <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                              </div>
                            </ng-container>
                            <ng-template #num2>
                                <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;font-size: 14px !important;white-space: nowrap;background: #179a13;" [style.width]="100*score.score/score.max_score+'%'" >
                                  <span style="padding-right: 4px;"> Your Score : {{score.score_mm_ss_ms}}</span>
                                </div>                                                                 
                            </ng-template>                                                               
                        </div>
                      </div>                                                        
                    </div>                                                   
                  </td>
                  </tr> 
                  <tr *ngIf="score.score_type_name=='Stopwatch'">
                    <td colspan="2" valign="top" class="textContent" style="padding: 0px 10px 0px 10px;">
                      <div class="uk-grid" style="margin-top:0px;margin-left:0px;" *ngIf="score.parameter_score_compare_criteria_key=='NA'">
                        <div class="uk-width-1-1" style="margin:0px; padding:0px;">
                          <div class="progress" style="float:left; width:100%;border-top-right-radius:4px;border-bottom-right-radius:4px;height: 20px !important;">                                                         
                            <ng-container *ngIf="score.batch_avg_score>=score.score else numavg2">
                              <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" >
                                <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                              </div>
                            </ng-container>
                            <ng-template #numavg2>
                                <div class="progress-bar progress-bar-striped" style="text-align:right;width:100%; float:left;border-top-right-radius:4px;border-bottom-right-radius:4px;white-space: nowrap;background: #3e76ec;" [style.width]="100*score.batch_avg_score/score.score+'%'" >
                                  <span style="padding-right: 4px;"> Average : {{score.batch_avg_score_mm_ss_ms}}</span>
                                </div>                                                                                                                             
                            </ng-template>                                                              
                          </div>
                        </div>                                                        
                      </div>                                                   
                    </td>
                  </tr>
                <!-- NA score type --> 

                <!-- comment -->
                  <tr>                   
                    <td colspan="2" valign="top" class="textContent" style="border-top: 1px solid" [style.color]="bottom_border" *ngIf="score.is_allow_show_comment=='1' && score.comments!=''">
                      <span style="margin:5px 0px; font-size:12px;line-height: 15px;"  [style.color]="text_color"><b>Observation: </b></span>
                      <div [innerHTML]="score.comments | linky"  style="display: inline-block;margin:0px 0px; font-size:12px;" [style.color]="text_color"></div>
                    </td>
                  </tr>
                <!-- comment -->
                </ng-container>
                </ng-container>
                </table> 
            </ng-container>
          </ng-container>
          <table border="0" cellpadding="10" cellspacing="5" [style.background]="report_card_color" width="100%" class="flexibleContainer" *ngIf="report_type=='by_criteria'" >
            <tr *ngIf="specific_student_report_card_data[0]['add_signature_flag']==1">
              <td>
                <div > 
                  <img [src]="specific_student_report_card_data[0]['signatures'][0]['aws_signature_image']"
                    style="margin: 0px auto;width: 100%;"/>                   
                </div>
              </td>
            </tr>
        </table>
      </div>
    </div>



      <ng-container *ngIf="organization_id=='1784'">
        <div id="optimum_card-mob" bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0"
          offset="0" *ngIf="report_name=='Musculoskeletal Report'">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" bgcolor="#FFFFFF" id="bodyTable"
            style="padding:10px; table-layout: fixed;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <h1 class="template-title">MUSCULOSKELETAL REPORT</h1>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle"><b>General Body Measurement</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Height'"><b>Height:</b>
                        {{score.score}} {{score.score_unit}}</td>
                    </ng-container>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Weight'"><b>Weight:</b>
                        {{score.score}} {{score.score_unit}}</td>
                    </ng-container>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='BMI'"><b>BMI:</b>
                        {{score.score}} </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle"><b>Body Type</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="center" valign="middle" colspan="3"
                        *ngIf="score.performance_parameter_name=='Body Type'"><b>{{score.score}}</b></td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>MUSCULOSKELETAL SYSTEM </b>
                </div>
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Severe injury</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Severe Injury'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Minor injury</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Minor Injury'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Operation on the musculoskeletal system</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Operation on the musculoskeletal system'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Current complaints, aches or pain</b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Current Complaints'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Current diagnosis </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Current Diagnosis'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>Postural Component <small>(on
                      observation)</small></b></div>
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Shoulder symmetry </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Shoulder Symmetry'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Roundness of Shoulder </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Roundness of Shoulder'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Hip symmetry </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Hip Symmetry'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Thoracic spine curvature </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Thoracic Spine Curvature'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Lumbar lordosis </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Lumbar Lordosis'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Spine curvature </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Spine Curvature'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Knee alignment </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Knee Alignment'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Ankle and Foot </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Ankle and Foot'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>Range of motion</b></div>
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Shoulder </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Shoulder'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Elbow </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Elbow'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Wrist </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Wrist'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Hip </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Hip'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Knee </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Knee'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Ankle </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Ankle'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <div style="text-align: left;padding-left: 15px; padding-bottom: 6px;"><b>Muscles</b></div>
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Adductors </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Adductors'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Hamstrings </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Hamstrings'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Iliopsoas </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Iliopsoas'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Rectus femoris </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4"
                        *ngIf="score.performance_parameter_name=='Rectus Femoris'">{{score.score}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td align="left" valign="middle" colspan="4"><b>Calves </b></td>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Calves'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td align="left" valign="middle"><b>Recommendations: </b></td>
                  </tr>
                  <tr>
                    <ng-container *ngFor="let score of default_scores">
                      <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Recommendations'">
                        {{score.score}}</td>
                    </ng-container>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>


        <div class="main-cls" offset="0" toppadding="0" leftpadding="0" id="optimum_card" *ngIf="report_name!='Musculoskeletal Report'">
          <table width="100%" height="700" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td *ngFor="let data of specific_student_report_card_data; let i=index">

                  <div class="uk-grid">
                    <div class="uk-width-medium-1-2 logo">
                      <p><img src="assets/images/OptimumTranparentLogo-min.png" style="width:180px; margin:auto;"></p>
                    </div>
                    <div class="uk-width-medium-1-2">
                      <table class="name-info" width="100%" border="0" cellspacing="0" cellpadding="0"
                        style="font-size:13px; margin-top:15px; margin-bottom:10px;">
                        <tbody>
                          <tr>
                            <td style="width:100px;"><b>Student Name</b></td>
                            <td>:</td>
                            <td>{{data.player_name}}</td>
                          </tr>
                          <tr>
                            <td><b>Age</b></td>
                            <td>:</td>
                            <td>{{data.age}}</td>
                          </tr>
                          <tr>
                            <td><b>Class</b></td>
                            <td>:</td>
                            <td>{{data.academy_group_name}}</td>
                          </tr>
                          <tr>
                            <td><b>Gender</b></td>
                            <td>:</td>
                            <td>{{data.gender}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="uk-grid" style="margin-top:0px; margin-bottom:10px;">
                    <div class="uk-width-medium-1-1">
                      <ul class="listing" style="border: 0px; padding-top: 15px; position: relative;text-align: left;">
                        <li style="display:inline-block !important"><span class="color-g"
                            style="background:#008000"></span> Good</li>
                        <li style="display:inline-block !important"><span class="color-g"
                            style="background:#00bfff"></span> Average </li>
                        <li style="display:inline-block !important"><span class="color-g"
                            style="background:#ff0000"></span> Needs Improvement</li>
                      </ul>
                    </div>
                  </div>
                  <div class="uk-grid">
                    <ng-container
                      *ngFor="let info of specific_student_report_card_data[i]['performance_parameters'];let j=index">
                      <div class="uk-width-medium-1-2" style="padding:20px 15px;"
                        *ngFor="let score of specific_student_report_card_data[i]['performance_parameters'][j]['scores'];">
                        <div class='contentEditableContainer contentTextEditable'>

                          <div class="c100 p100 small">
                            <span style="text-align: center; display: block;">
                              <ng-container *ngIf="info.performance_parameter_name=='1 Flexibility'">
                                <img src="assets/images/yoga.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='2 Speed 30 Meter'">
                                <img src="assets/images/runner.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='3 Medicine Ball Throw'">
                                <img src="assets/images/Medicine Ball Throw.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='7 Agility 10x6'">
                                <img src="assets/images/quick.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='5 Reaction Time'">
                                <img src="assets/images/stopwatch.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='6 Anticipation Time'">
                                <img src="assets/images/AnticipationTime.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='4 Standing Vertical Jump'">
                                <img src="assets/images/SVJ.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='8 Endurance'">
                                <img src="assets/images/runner.png" style="width:40px; margin:20px auto">
                              </ng-container>
                              <ng-container *ngIf="info.performance_parameter_name=='9 Strength Analysis'">
                                <ng-container *ngIf="score.score_label=='Grip Strength'">
                                  <img src="assets/images/Grip Strength.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="score.score_label=='Back Strength'">
                                  <img src="assets/images/Back Strength.png" style="width:40px; margin:20px auto">
                                </ng-container>

                              </ng-container>
                            </span>
                            <div class="slice">
                              <div class="bar" [style.border-color]="score.color"></div>
                              <div class="fill" [style.border-color]="score.color"></div>
                            </div>
                          </div>

                          <div class='contentEditable' style='text-align: left;'>
                            <h2 style="font-size: 13px; font-weight: bold;;">
                              <ng-container *ngIf="info.performance_parameter_name=='9 Strength Analysis' else label">
                                {{score.score_label}}</ng-container>
                              <ng-template #label>{{info.performance_parameter_name| slice:2:25}}</ng-template> :
                              <span style="font-size: 19px; color:  #ffa630"> {{score.score}}</span><small>
                                ({{score.score_unit}})</small>
                            </h2>
                            <p style="font-size: 11px;font-weight: bold;">Ideal Score : ({{score.min_score}} -
                              {{score.max_score}})</p>

                            <ng-container
                              *ngIf="info.performance_parameter_name=='9 Strength Analysis' else description">
                              <ng-container *ngIf="score.score_label=='Grip Strength'">
                                <p>The aim of the test is to measure the maximum strength of fore arm muscles.</p>

                              </ng-container>
                              <ng-container *ngIf="score.score_label=='Back Strength'">
                                <p>The aim of the test is to measure the maximum strength of back and core muscles.</p>
                              </ng-container>
                            </ng-container>
                            <ng-template #description>
                              <p>{{info.performance_parameter_description}}</p>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <!-------------------->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
      <ng-container *ngIf="organization_id=='1783'">
          <div id="optimum_card-mob" bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0"
            offset="0" *ngIf="report_name=='Musculoskeletal Report' || report_name=='LSBA-Musculoskeletal' || report_name=='ODMT-Musculoskeletal' || report_name=='SCA-Musculoskeletal'">
            <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" bgcolor="#FFFFFF" id="bodyTable"
              style="padding:10px; table-layout: fixed;">
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <h1 class="template-title">MUSCULOSKELETAL REPORT</h1>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle"><b>General Body Measurement</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Height'"><b>Height:</b>
                          {{score.score}} {{score.score_unit}}</td>
                      </ng-container>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Weight'"><b>Weight:</b>
                          {{score.score}} {{score.score_unit}}</td>
                      </ng-container>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='BMI'"><b>BMI:</b>
                          {{score.score}} </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle"><b>Body Type</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="center" valign="middle" colspan="3"
                          *ngIf="score.performance_parameter_name=='Body Type'"><b>{{score.score}}</b></td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>MUSCULOSKELETAL SYSTEM </b>
                  </div>
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Severe injury</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Severe Injury'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Minor injury</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Minor Injury'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Operation on the musculoskeletal system</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Operation on the musculoskeletal system'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Current complaints, aches or pain</b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Current Complaints'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Current diagnosis </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Current Diagnosis'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>Postural Component <small>(on
                        observation)</small></b></div>
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Shoulder symmetry </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Shoulder Symmetry'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Roundness of Shoulder </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Roundness of Shoulder'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Hip symmetry </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Hip Symmetry'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Thoracic spine curvature </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Thoracic Spine Curvature'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Lumbar lordosis </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Lumbar Lordosis'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Spine curvature </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Spine Curvature'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Knee alignment </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Knee Alignment'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Ankle and Foot </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Ankle and Foot'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <div style="text-align: left;padding-left: 15px;padding-bottom: 6px;"><b>Range of motion</b></div>
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Shoulder </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Shoulder'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Elbow </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Elbow'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Wrist </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Wrist'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Hip </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Hip'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Knee </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Knee'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Ankle </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Ankle'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <div style="text-align: left;padding-left: 15px; padding-bottom: 6px;"><b>Muscles</b></div>
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Adductors </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Adductors'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Hamstrings </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Hamstrings'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Iliopsoas </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Iliopsoas'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Rectus femoris </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4"
                          *ngIf="score.performance_parameter_name=='Rectus Femoris'">{{score.score}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" colspan="4"><b>Calves </b></td>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" colspan="4" *ngIf="score.performance_parameter_name=='Calves'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top" id="bodyCell">
                  <table border="1" bordercolor="#888888" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="left" valign="middle"><b>Recommendations: </b></td>
                    </tr>
                    <tr>
                      <ng-container *ngFor="let score of default_scores">
                        <td align="left" valign="middle" *ngIf="score.performance_parameter_name=='Recommendations'">
                          {{score.score}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
  
  
          <div class="main-cls" offset="0" toppadding="0" leftpadding="0" id="optimum_card"
            *ngIf="report_name!='Musculoskeletal Report' && report_name!='LSBA-Musculoskeletal' && report_name!='ODMT-Musculoskeletal' && report_name!='SCA-Musculoskeletal'">
            <table width="100%" height="700" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td *ngFor="let data of specific_student_report_card_data; let i=index">
  
                    <div class="uk-grid">
                      <div class="uk-width-medium-1-2 logo">
                        <p>
                          <ng-container *ngIf="data.academy_center_id=='300'">
                             <img src="assets/images/mkt.jpg" style="width:180px; margin:auto;">
                          </ng-container>
                          <ng-container *ngIf="data.academy_center_id=='303'">
                              <img src="assets/images/ODMT.jpg" style="width:180px; margin:auto;">
                           </ng-container>
                           <ng-container  *ngIf="data.academy_center_id=='304'">
                              <img src="assets/images/SanjayCA.jpg" style="width:180px; margin:auto;">
                           </ng-container>
                           <ng-container *ngIf="data.academy_center_id=='302'">
                              <img src="assets/images/LSBA.jpg" style="width:180px; margin:auto;">
                           </ng-container>
                        </p>
                      </div>
                      <div class="uk-width-medium-1-2">
                        <table class="name-info" width="100%" border="0" cellspacing="0" cellpadding="0"
                          style="font-size:13px; margin-top:15px; margin-bottom:10px;">
                          <tbody>
                            <tr>
                              <td style="width:100px;"><b>Student Name</b></td>
                              <td>:</td>
                              <td>{{data.player_name}}</td>
                            </tr>
                            <tr>
                              <td><b>Age</b></td>
                              <td>:</td>
                              <td>{{data.age}}</td>
                            </tr>
                            <tr>
                              <td><b>Class</b></td>
                              <td>:</td>
                              <td>{{data.academy_group_name}}</td>
                            </tr>
                            <tr>
                              <td><b>Gender</b></td>
                              <td>:</td>
                              <td>{{data.gender}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
  
                    <div class="uk-grid" style="margin-top:0px; margin-bottom:10px;">
                      <div class="uk-width-medium-1-1">
                        <ul class="listing" style="border: 0px; padding-top: 15px; position: relative;text-align: left;">
                          <li style="display:inline-block !important"><span class="color-g"
                              style="background:#008000"></span> Good</li>
                          <li style="display:inline-block !important"><span class="color-g"
                              style="background:#00bfff"></span> Average </li>
                          <li style="display:inline-block !important"><span class="color-g"
                              style="background:#ff0000"></span> Needs Improvement</li>
                        </ul>
                      </div>
                    </div>
                    <div class="uk-grid">
                      <ng-container
                        *ngFor="let info of specific_student_report_card_data[i]['performance_parameters'];let j=index">
                        <div class="uk-width-medium-1-2" style="padding:20px 15px;"
                          *ngFor="let score of specific_student_report_card_data[i]['performance_parameters'][j]['scores'];">
                          <div class='contentEditableContainer contentTextEditable'>
  
                            <div class="c100 p100 small">
                              <span style="text-align: center; display: block;">
                                <ng-container *ngIf="info.performance_parameter_name=='Flexibility'">
                                  <img src="assets/images/yoga.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Speed 30 Meter'">
                                  <img src="assets/images/runner.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Medicine Ball Throw'">
                                  <img src="assets/images/Medicine Ball Throw.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Agility'">
                                  <img src="assets/images/quick.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Reaction Time'">
                                  <img src="assets/images/stopwatch.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Anticipation Time'">
                                  <img src="assets/images/AnticipationTime.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Standing Vertical Jump'">
                                  <img src="assets/images/SVJ.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Endurance'">
                                  <img src="assets/images/runner.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                
                                <ng-container *ngIf="info.performance_parameter_name=='Grip Strength'">
                                    <img src="assets/images/Grip Strength.png" style="width:40px; margin:20px auto">
                                  </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Back Strength'">
                                  <img src="assets/images/Back Strength.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Balance'">
                                  <img src="assets/images/balance.png" style="width:40px; margin:20px auto">
                                </ng-container>
                                <ng-container *ngIf="info.performance_parameter_name=='Strength Analysis'">
                                  <ng-container *ngIf="score.score_label=='Grip Strength'">
                                    <img src="assets/images/Grip Strength.png" style="width:40px; margin:20px auto">
                                  </ng-container>
                                  <ng-container *ngIf="score.score_label=='Back Strength'">
                                    <img src="assets/images/Back Strength.png" style="width:40px; margin:20px auto">
                                  </ng-container>
  
                                </ng-container>
                              </span>
                              <div class="slice">
                                <div class="bar" [style.border-color]="score.color"></div>
                                <div class="fill" [style.border-color]="score.color"></div>
                              </div>
                            </div>
  
                            <div class='contentEditable' style='text-align: left;'>
                              <h2 style="font-size: 13px; font-weight: bold;;">
                                <ng-container *ngIf="info.performance_parameter_name=='9 Strength Analysis' else label">
                                  {{score.score_label}}</ng-container>
                                <ng-template #label>{{info.performance_parameter_name}}</ng-template> :
                                <span style="font-size: 19px; color:  #ffa630"> {{score.score}}</span><small>
                                  ({{score.score_unit}})</small>
                              </h2>
                              <p style="font-size: 11px;font-weight: bold;">Ideal Score : ({{score.min_score}} -
                                {{score.max_score}})</p>
  
                              <ng-container
                                *ngIf="info.performance_parameter_name=='9 Strength Analysis' else description">
                                <ng-container *ngIf="score.score_label=='Grip Strength'">
                                  <p>The aim of the test is to measure the maximum strength of fore arm muscles.</p>
  
                                </ng-container>
                                <ng-container *ngIf="score.score_label=='Back Strength'">
                                  <p>The aim of the test is to measure the maximum strength of back and core muscles.</p>
                                </ng-container>
                              </ng-container>
                              <ng-template #description>
                                <p>{{info.performance_parameter_description}}</p>
                              </ng-template>
  
  
                            </div>
                          </div>
                        </div>
                      </ng-container>
  
                    </div>
  
  
  
  
  
  
  
                    <!-------------------->
  
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #document>
    <h4 mat-dialog-title>Document
      <button mat-icon-button style="float:right;" [mat-dialog-close]=" ">
        <mat-icon (click)="reset_array()">close</mat-icon>
      </button>
    </h4>
    <div *ngIf="image_doc_flag">
      <img [src]="doc_src"> </div>
    <div *ngIf="pdf_doc_flag">
      <a [href]="doc_src" download="report.pdf">
        <i class="fa fa-download fa-2x text-primary" aria-hidden="true"></i>
      </a>
    </div>
  </ng-template>